import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp }  from "vue";
import router from "./router";
import themes from "devextreme/ui/themes";

import App from "./App";
import appInfo from "./app-info";

import Config from "devextreme/core/config";

Config({
    licenseKey: 'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjAyMDM1ZWE0LTMxOWItNDI4ZS1iOWIyLTQyZGIwZmNiNDk2NiIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQyCn0=.k6sInOx2b2HyDfj/5c/KBZ7wbCTYPaJ0Ng570y8xpqEyomBRhqi23NkeOb6HX/8FTAJt1SuQOybvZ7Jcgq0r+VfBUYQzo0Z4qE7vKP7WWHv31Kisj2/qMoF+o6QR0tXeSDC9Ng==',
    forceIsoDateParsing: true,
    // editorStylingMode: 'outlined',
});

// import Form from "devextreme/ui/form";
// import Config from "devextreme/core/config";


// Config({
//     forceIsoDateParsing: false,
//     // editorStylingMode: 'outlined',
// });
// //
// Form.defaultOptions({
//     device: [
//         { deviceType: 'desktop' },
//         { deviceType: 'tablet' },
//         { deviceType: 'phone' },
//     ],
//     options: {
//         // labelMode: 'floating',
//         labelAlign: 'left'
//     }
// });

themes.initialized(() => {

    const app = createApp(App);
    app.use(router);
    app.config.globalProperties.$appInfo = appInfo;
    app.mount('#app');
});
